html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

body {
    background-color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
}

#root {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    padding: 48px 64px;
}

.navbar img {
    height: 45px;
}

#notif {
    width: 56px;
    height: 56px;
    cursor: pointer;
    border: none;
    border-radius: 6px;
    background-color: #5EFF75;
    align-items: center;
    justify-content: center;
    transition: background-color 0.1s linear;
}

#notif:hover {
    background-color: #45BC54FF;
}

#bell {
    color: white;
}

.main-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 128px 64px;
}

.text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 876px;
    height: 100%;
}

.bigtext {
    font-size: 88px;
    color: #000000;
    line-height: 96px;
    font-weight: 600;
    margin-bottom: 16px;
}

.smallertext {
    font-size: 32px;
    font-weight: 400;
    color: #3A3A3A;
    margin-bottom: 64px;
}

.email-wrap {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding-right: 128px;
}

#enter-email {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #7A7A7A;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    padding: 10px 16px;
    transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

#enter-email.highlight {
    border-color: #5EFF75;
    box-shadow: 0 0 10px #5EFF75;
    transition: box-shadow 0.3s ease-in-out;
}

.notify-me {
    border: none;
    border-radius: 8px;
    background-color: #5EFF75;
    color: white;
    padding: 8px 20px;
    height: auto;
    cursor: pointer;
    flex-shrink: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    transition: background-color 0.1s linear;
}

.notify-me:hover {
    background-color: #45BC54FF;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px 64px;
    background-color: #FFFFFF;
    color: #3A3A3A;
}

.footer a {
    color: #3A3A3A;
    transition: color 0.1s linear;
}

.footer a:hover {
    color: #5EFF75;
}

.confetti {
    width: 686px;
    position: fixed;
    bottom: -25px;
    right: -25px;
    z-index: 9999;
}

@media only screen and (max-width: 1475px) {
    .confetti {
        display: none;
    }
}

@media only screen and (max-width: 1023px) {
    .navbar {
        padding: 24px 32px;
    }

    .main-container {
        padding: 64px 32px;
    }

    .bigtext {
        font-size: 64px;
        line-height: 72px;
    }

    .smallertext {
        font-size: 24px;
        margin-bottom: 32px;
    }

    .email-wrap {
        flex-direction: column;
        gap: 8px;
        padding-right: 0;
    }

    .footer {
        padding: 16px 32px;
        flex-direction: column;
        gap: 16px;
    }

    #enter-email {
        align-self: center;
    }

}

@media only screen and (max-width: 768px) {
    .navbar {
        flex-direction: column;
        gap: 16px;
    }

    .main-container {
        padding: 32px 16px;
    }

    .bigtext {
        font-size: 48px;
        line-height: 56px;
    }

    .smallertext {
        font-size: 20px;
        margin-bottom: 16px;
    }

    .email-wrap {
        flex-direction: column;
        gap: 8px;
        padding-right: 0;
    }

    .footer {
        padding: 16px;
        flex-direction: column;
        gap: 16px;
    }

    #notif {
        display: none;
    }

    #enter-email {
        width: calc(100% - 32px);
        margin: 0 auto;
    }
}

@media only screen and (max-width: 500px) {
    .footer {
        font-size: 14px;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 400px) {
    .footer {
        font-size: 12px;
    }
}

@media only screen and (max-width: 300px) {
    .footer {
        font-size: 10px;
    }
}

#enter-email.error {
    border-color: red;
    box-shadow: 0 0 10px red;
}

@keyframes popup {
    0% {
        transform: translateX(-50%) scale(0.5);
        opacity: 0;
    }
    50% {
        transform: translateX(-50%) scale(1.1);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) scale(1);
        opacity: 1;
    }
}

#notification-success {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #5EFF75;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 1000;
    opacity: 0;
    font-weight: 500;
    font-size: 16px;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

#notification-success.visible {
    opacity: 1;
    transform: translateX(-50%) scale(1);
    animation: popup 0.5s ease-in-out;
}

#notification-success.hidden {
    opacity: 0;
    transform: translateX(-50%) scale(0.5);
}
